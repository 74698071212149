// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_en_US: ITranslations = {
  en_US: {
    "QPPhoneNumbers": "QuantiParts Phone Numbers",
    "account.accountSelected": "Account selected",
    "account.selectingAccount": "Selecting the account...",
    "add": "Add",
    "addFilter": "Add filter",
    "addInstallation": "Add installation",
    "addInstallation.nameIsRequired": "Name is required",
    "addInstallation:energy": "Add power plant",
    "addInstallation:marine": "Add vessel",
    "all": "All",
    "allInstallations": "All installations",
    "allInstallations:energy": "All power plants",
    "allInstallations:marine": "All vessels",
    "applyFilter": "Apply filter",
    "askMeLater": "Ask Me Later",
    "attachments": "Attachments",
    "attachments.browse": "Browse for files...",
    "attachments.drop": "Drop your attachments here or",
    "auth.continueToUseOS": "Continue to use Wärtsilä Online",
    "auth.logout": "Log out",
    "auth.punchOutMode": "PunchOut mode",
    "auth.readOnlyMode": "Read-only mode",
    "auth.redirectingToLogin": "Redirecting to login...",
    "auth.sessionExpired": "Session timeout limit has been reached, you will be redirected to log in page",
    "auth.sessionWillExpire": "Your session will soon be expired",
    "browse": "Browse {type}",
    "button.cancel": "Cancel",
    "button.close": "Close",
    "button.next": "Next",
    "button.previous": "Previous",
    "button.saveForLater": "Save for later",
    "button.send": "Send",
    "button.skip": "Skip",
    "button.updateDraft": "Update draft",
    "cancel": "Cancel",
    "category": "Category",
    "change": "Change",
    "changePasswordDialog.success": "Password changed",
    "chooseDocumentType": "Filter documents",
    "chooseFilter": "Choose filter…",
    "clearFilters": "Clear filters",
    "comment.commentIsRequired": "Comment is required",
    "comment.postComment": "Post comment",
    "comment.replyComment": "Reply",
    "comment.writeYourComment": "Write your comment",
    "comment.writeYourReply": "Write your reply",
    "comment.you": "You",
    "commenting": "Commenting",
    "confirmAndContinue": "Confirm and Continue",
    "contact": "Contact",
    "contact.contactQPCustomerSupport": "Contact QuantiParts Customer Support",
    "contact.contactWartsilaCustomerSupport": "Contact Wärtsilä Customer Support",
    "contact.moreInformationLink": "More information about Wärtsilä Land & Sea Academy can be found in {url}",
    "contact.subject": "Subject",
    "contact.subjectIsRequired": "Subject is required",
    "contact.yourMessage": "Your message",
    "contact.yourMessageIsRequired": "Message is required",
    "contactCustomerSupport": "Contact Online Support",
    "contactOrganization": "Contact {organization}",
    "contextHelp": "Quick tour",
    "customerSupportTicket": "Wärtsilä Online Support Ticket",
    "darkModeTheme": "Dark mode",
    "date": "Date",
    "daysFromLastUpdate": "Days from last update",
    "delete": "Delete",
    "didYouMean": "Did you mean:",
    "dismissAll": "Dismiss all",
    "download.downloadReady": "Download ready!",
    "download.equipmentNotFound": "Could not find the article for the product",
    "download.error": "There was an error with the download",
    "download.lengthyDownload": "This might take several minutes",
    "download.preparingDownload": "Preparing your download",
    "draft.confirmDelete": "Are you sure you want to delete this draft?",
    "draft.confirmDeleteTitle": "Delete draft?",
    "draft.continueEditing": "Continue editing",
    "draft.delete": "Delete draft",
    "edit": "Edit",
    "editAll": "Edit all",
    "engine": "Engine",
    "equipment": "Product",
    "equipment.category.cargo": "Equipment for cargo",
    "equipment.category.crewAndPassenger": "Equipment for crew and passengers",
    "equipment.category.machineryMain": "Machinery main components",
    "equipment.category.machinerySystems": "Systems for machinery main components",
    "equipment.category.ship": "Ship equipment",
    "equipment.category.shipCommon": "Ship common systems",
    "equipment.configuration": "Configuration",
    "equipment.nickname": "Product nickname",
    "equipment.performanceData": "Performance",
    "equipment.productTypes": "Select Product Reference Type",
    "equipment.title": "Title",
    "equipment.validation.nicknameTooLong": "The maximum length for nickname is {maxLength}",
    "equipmentCategories": "Equipment categories",
    "equipmentNotFound": "No products exist",
    "error.networkError": "Network error",
    "error.networkErrorPleaseCheckConnectivity": "Please check your connectivity.",
    "error.networkErrorRefreshPrompt": "Try refreshing the current page",
    "error.readOnlyErrorMsg": "You are in read only mode.",
    "error.readOnlyErrorTitle": "Read only error",
    "error.refreshPage": "Something went wrong. Please refresh your page",
    "export": "Export",
    "export.chooseDelimiter": "Choose separator delimiter for export",
    "export.comma": "Comma",
    "export.semicolon": "Semicolon",
    "fileUpload.errors.attachmentRejected": "Attachment rejected",
    "fileUpload.errors.fileTooBig": "The file you tried to upload is too big",
    "fileUpload.errors.uploadLimit": "The upload limit is {limit}MB",
    "fileUpload.errors.duplicateFilename": "Filenames must be unique",
    "fileUpload.errors.removeDuplicates": "Please remove any duplicate attachments",
    "fileUpload.errors.supportedFileTypes": "Supported file types:",
    "fileUpload.errors.typeIsNotSupported": "File type you tried to upload is not supported",
    "fileUpload.noContactPerson": "No contact person. Can't send message!",
    "fileUpload.sizeLimit": "Maximum upload file size: {limit}MB",
    "fileUpload.uploadAborted": "File upload was aborted",
    "fileUpload.uploadFailed": "File upload failed",
    "fileUpload.userHasReadOnlyAccess": "User has read-only access",
    "filterProducts": "Filter products",
    "filterRequests": "Filter requests",
    "iDontKnow": "I don't know",
    "index.installationDetails": "Installation Details",
    "index.installationNotifications": "Installation Notifications",
    "informationAboutTrainings": "Information about Wärtsilä trainings",
    "informationAboutTrainingsQP": "Information about QuantiParts trainings",
    "installation": "Installation",
    "installation.classificationSociety": "Classification society",
    "installation.cluster": "Vessel cluster",
    "installation.countryOfOperations": "Country of operations",
    "installation.countryOfRegistration": "Country of registration",
    "installation.deliveryDate": "Delivery date",
    "installation.description": "Description",
    "installation.equipment": "Equipment",
    "installation.imo": "IMO",
    "installation.imoNumber": "IMO Number",
    "installation.information": "Information",
    "installation.inspectionDate": "Inspection date",
    "installation.leadWarrantyManager": "Lead warranty manager",
    "installation.name": "Name",
    "installation.nextPlannedMaintenance": "Next planned maintenance",
    "installation.owner": "Owner",
    "installation.projectName": "Project name",
    "installation.segment": "Vessel segment",
    "installation.status": "Status",
    "installation.type": "Type",
    "installation.vesselName": "Vessel name",
    "installation.vesselOwner": "Vessel owner",
    "installation.vesselStatus": "Vessel status",
    "installation.vesselType": "Vessel type",
    "installation.wartsilaProjectManager": "Wärtsilä project manager",
    "installation.yard": "Yard",
    "installation:energy": "Power plant",
    "installation:marine": "Vessel",
    "installations": "Installations",
    "installations:energy": "Power plants",
    "installations:marine": "Vessels",
    "language": "Language",
    "language.languageNotFound": "Couldn't find language with language code",
    "languageDisclaimer": "Submitting this Request in English will accelerate the handling process",
    "lastUpdate": "Last update",
    "latestUpdates": "Latest updates",
    "letsStart": "Let's start",
    "lightModeTheme": "Light mode",
    "loading": "Loading...",
    "logout": "Logout",
    "main.loadMore": "Load more",
    "main.pageNotFound": "The URL you followed was not found: {url}",
    "main.pageNotFoundTitle": "Not found",
    "main.wartsilaOnlineHasBeenUpdated": "Wärtsilä Online has been updated",
    "main.whatIsNew": "What's new?",
    "manufacturerSerialNumber": "Manufacturer serial number",
    "month.april": "April",
    "month.april.short": "Apr",
    "month.august": "August",
    "month.august.short": "Aug",
    "month.december": "December",
    "month.december.short": "Dec",
    "month.february": "February",
    "month.february.short": "Feb",
    "month.january": "January",
    "month.january.short": "Jan",
    "month.july": "July",
    "month.july.short": "Jul",
    "month.june": "June",
    "month.june.short": "Jun",
    "month.march": "March",
    "month.march.short": "Mar",
    "month.may": "May",
    "month.may.short": "May",
    "month.november": "November",
    "month.november.short": "Nov",
    "month.october": "October",
    "month.october.short": "Oct",
    "month.september": "September",
    "month.september.short": "Sep",
    "months": "months",
    "mostRecentUpdatedEngine": "Most recently updated engine",
    "myFleet": "My installations",
    "myFleet:marine": "My fleet",
    "myInstallations": "My installations",
    "myInstallations:energy": "My power plants",
    "myInstallations:marine": "My vessels",
    "myProfile": "My Profile",
    "na": "N/A",
    "new": "New",
    "news.furtherQuestions": "Additional comments or questions",
    "news.productsAreRequired": "Product(s) are required",
    "news.quotationRequestSent": "Quotation request sent.",
    "news.readMore": "Read more",
    "news.requestQuotation": "Request quotation",
    "news.selectTheProductsForTheQuotation": "Select the product(s) for the quotation",
    "news.sendingQuotationRequest": "Sending quotation request.",
    "news.youAreAskingQuotationFor": "You are asking quotation for {productName}.",
    "nickname": "Nickname",
    "no": "No",
    "noDocumentsFound": "No documents found for",
    "noEquipmentFound": "No equipment found",
    "noVisibleInstallationsFound": "You have not defined any visible {sector}",
    "noVisibleInstallationsFoundHelp": "You can do this from {sector}",
    "notApplicable": "Not applicable",
    "notificationList.markedNotificationsAsRead": "Marked {count} notifications as read",
    "notificationList.title.NewInvoiceAvailable": "New invoice generated",
    "notificationList.title.SalesOrderDeliveryCollected": "Delivery {deliveryId} has been collected from the warehouse",
    "notificationList.title.SalesOrderDeliveryDelivered": "Delivery {deliveryId} has been delivered to the destination",
    "notificationList.title.SalesOrderDeliveryDispatched": "Delivery {deliveryId} is dispatched from the warehouse",
    "notificationList.title.SalesOrderDeliveryDocumentAvailable": "{documentType} available for the delivery {deliveryId}",
    "notificationList.title.SalesOrderDeliveryDocumentAvailableMultiple": "New documents available for the delivery {deliveryId}",
    "notificationList.title.SalesOrderDeliveryReadyToBeCollected": "Delivery {deliveryId} is ready to be collected from the warehouse",
    "notificationList.title.WarrantyClaimDeliveryCollected": "Delivery {deliveryId} has been collected from the warehouse",
    "notificationList.title.WarrantyClaimDeliveryDelivered": "Delivery {deliveryId} has been delivered to the destination",
    "notificationList.title.WarrantyClaimDeliveryDispatched": "Delivery {deliveryId} is dispatched from the warehouse",
    "notificationList.title.WarrantyClaimDeliveryDocumentAvailable": "{documentType} available for the delivery {deliveryId}",
    "notificationList.title.WarrantyClaimDeliveryDocumentAvailableMultiple": "New documents available for the delivery {deliveryId}",
    "notificationList.title.WarrantyClaimDeliveryReadyToBeCollected": "Delivery {deliveryId} is ready to be collected from the warehouse",
    "notificationList.title.bulletinUpdated": "A new bulletin has been released: {title}",
    "notificationList.title.classificationAvailable": "Classification certificate available in the order",
    "notificationList.title.classificationAvailable.plural": "Classification certificates available in the order",
    "notificationList.title.criticalBulletinUpdated": "Bulletin for immediate attention: {title}",
    "notificationList.title.documentUpdated": "A new {document} has been released: {title}",
    "notificationList.title.equipmentRunningHoursUpdate": "Running hours should be updated",
    "notificationList.title.manualUpdated": "A new manual chapter has been released: {title}",
    "notificationList.title.newAttachment": "New attachment",
    "notificationList.title.newCMReport": "New Maintenance and Condition Report has been published for {installationName}",
    "notificationList.title.newCMReportBudget": "New Maintenance Planning Budget report has been published for {installationName}",
    "notificationList.title.newCMReportDMP": "New Dynamic Maintenance Plan statement has been published for {installationName}",
    "notificationList.title.newCMReportQuarterly": "Maintenance Planning report has been published for {installationName}",
    "notificationList.title.newCMReportForecasting": "Maintenance Forecast report has been published for {installationName}",
    "notificationList.title.newComment": "New comment",
    "notificationList.title.newRecommendation": "New recommendation available",
    "notificationList.title.newRequest": "New request has been created",
    "notificationList.title.newRequestDistributor": "Request was sent to a distributor who handles it",
    "notificationList.title.newSalesOrder": "New order created",
    "notificationList.title.newServiceQuotation": "New quotation created",
    "notificationList.title.newSparePartClaim": "New spare part claim",
    "notificationList.title.newWarrantyClaim": "New warranty request",
    "notificationList.title.newWarrantyClaimAttachment": "New warranty request attachment",
    "notificationList.title.newWarrantyClaimComment": "New warranty request comment",
    "notificationList.title.quotationIsAboutToExpire": "The quotation is about to expire",
    "notificationList.title.resolvedSparePartClaim": "Resolved spare part claim",
    "notificationList.title.salesOrderCollectionDateChanged": "Collection date change in the order",
    "notificationList.title.salesOrderConfirmed": "Sales order has been confirmed",
    "notificationList.title.salesOrderEtaDateChanged": "ETA date change in the order",
    "notificationList.title.salesOrderProformaAvailable": "Proforma available for the order",
    "notificationList.title.serviceQuotationProformaAvailable": "Proforma available for the quotation",
    "notificationList.title.serviceQuotationQuoted": "Quotation has been quoted",
    "notificationList.title.sparePartsUpdated": "A new spare parts section has been released: {title}",
    "notificationList.title.techReportUpdated": "A new technical report has been released: {title}",
    "notificationList.title.techRequestSolutionPlan": "New solution plan added",
    "notificationList.title.waitingForRating": "Request solved - waiting for rating",
    "notificationList.title.waitingForReply": "Waiting for reply",
    "notificationList.title.warrantyContactUpdated": "Warranty contact updated",
    "notifications.history": "Notification summary",
    "notifications.lastWeek": "Last week",
    "notifications.markAllRead": "Mark all as read",
    "notifications.markAsRead": "Mark as read",
    "notifications.markedAsRead": "Marked as read",
    "notifications.moreNewNotifications": "and {number} more new notifications",
    "notifications.notFound": "No notifications found",
    "notifications.older": "Older",
    "notifications.openPushNotificationLink": "Open...",
    "notifications.pushNotificationsOff": "Browser notifications off",
    "notifications.pushNotificationsOn": "Browser notifications on",
    "notifications.subscriptions.daily": "Daily",
    "notifications.subscriptions.emailFrequency": "Email frequency",
    "notifications.subscriptions.emailNotificationSubscription": "Email notification subscription",
    "notifications.subscriptions.immediate": "Immediate",
    "notifications.subscriptions.subscribe": "Subscribe",
    "notifications.subscriptions.subscribeToOrder": "Subscribe to email notifications for order {orderNumber}",
    "notifications.subscriptions.subscribeToOrderDaily": "You will receive a daily summary of all the updates for the orders you have subscribed to",
    "notifications.subscriptions.subscribeToOrderImmediate": "You will receive immediate e-mail notifications of all the updates related to your order",
    "notifications.subscriptions.subscribeToQuotation": "Subscribe to email notifications for quotation {quotationNumber}",
    "notifications.subscriptions.subscribeToQuotationDaily": "You will receive a daily summary of all the updates for the quotations you have subscribed to",
    "notifications.subscriptions.subscribeToQuotationImmediate": "You will receive immediate e-mail notifications of all the updates related to your quotation",
    "notifications.subscriptions.subscribed": "Subscribed",
    "notifications.thisWeek": "This week",
    "notifications.title": "Notifications ({unreadNotifications})",
    "notifications.today": "Today",
    "notifications.yesterday": "Yesterday",
    "ok": "OK",
    "optionalFieldTitle": "optional",
    "orderBeingProcessed": "Order being processed",
    "phone": "Phone",
    "pleaseAddInstallation": "Please select an installation!",
    "pleaseAddInstallation:energy": "Please select a power plant!",
    "pleaseAddInstallation:marine": "Please select a vessel!",
    "productName": "Product name",
    "productNumber": "Product number",
    "productType": "Product Reference Type",
    "profile.errorWhileSelectingProfile": "Error while selecting a profile",
    "profile.errorWhileUpdatingProfile": "Error while updating a profile",
    "profile.profileSelected": "Profile selected",
    "profile.profileUpdated": "Profile updated",
    "profile.updating": "Updating the profile…",
    "quotationBeingProcessed": "Quotation being processed",
    "releaseNotes.contactDisclaimer": "Wärtsilä will contact you by email for the date and time of your demo.",
    "releaseNotes.interestedNewFetures": "Interested in new features?",
    "releaseNotes.openButton": "Wärtsilä Online Release Notes",
    "releaseNotes.readMore": "See all version updates",
    "releaseNotes.requestDemo": "Request a demo",
    "releaseNotes.requestDemoFor": "Request a demo for",
    "releaseNotes.selectFeatures": "Please select the features you are interested in",
    "releaseNotes.version": "Version {version}",
    "removeInstallation": "Remove installation",
    "removeInstallation:energy": "Remove power plant",
    "removeInstallation:marine": "Remove vessel",
    "request.draftSaved": "Draft saved",
    "request.draftSavingError": "Error while saving draft",
    "request.savingDraft": "Saving draft...",
    "request.send": "Send request",
    "request.sending": "Sending request...",
    "request.sendingError": "Error while sending request",
    "request.sent": "Request sent.",
    "requestDemoFeature.agreements": "Agreement related functionality",
    "requestDemoFeature.commerce": "Commerce",
    "requestDemoFeature.demo": "Full demo",
    "requestDemoFeature.support": "Support (Warranty Claim, Spare Part Claim, Technical Support)",
    "requestDemoFeature.techdoc": "Technical Documentation",
    "rfq.accountIsRequired": "Account is required!",
    "rfq.additionalComments": "Additional comments about scope",
    "rfq.additionalCommentsIsRequired": "Additional comments about scope are required!",
    "rfq.estimatedEndDateIsRequired": "Estimated end date is required!",
    "rfq.estimatedStartDateIsRequired": "Estimated start date is required!",
    "rfq.fieldService": "Field service",
    "rfq.filterByCategory": "Filter by category",
    "rfq.includeSpareParts.no": "No, I have already ordered the required spare parts",
    "rfq.includeSpareParts.question": "Would you like to include spare parts recommended by Wärtsilä?",
    "rfq.includeSpareParts.yes": "Yes, please include recommended spare parts with the offer",
    "rfq.includedSpareParts": "Included Spare Parts",
    "rfq.isVesselInDryDock": "Will the vessel be in dry-dock?",
    "rfq.locationCity": "Location detail (city)",
    "rfq.noProductSelected": "No product selected",
    "rfq.noProductsFound": "No products found",
    "rfq.offerScope": "Offer scope",
    "rfq.offerScopeIsRequired": "Offer scope is required!",
    "rfq.otherQuotations": "Other quotations",
    "rfq.productsSelected": "Product(s) selected",
    "rfq.quotationDetails": "Quotation details",
    "rfq.quotationType": "Quotation type",
    "rfq.quotationTypeIsRequired": "Quotation type is required",
    "rfq.runningHoursOutdated": "Running hours are more than 3 months old for some equipment. Please update them before continuing.",
    "rfq.runningHoursRequireConfirmation": "The entered running hours need confirmation. Please make an update request through the running hours page.",
    "rfq.scheduleAndLocation": "Schedule and location",
    "rfq.scope": "Scope",
    "rfq.scope.scheduledMaintenance": "Scheduled Maintenance",
    "rfq.scope.troubleshootingInspection": "Troubleshooting/Inspection",
    "rfq.selectDateRange": "Select date range",
    "rfq.selectedProducts": "Selected products",
    "rfq.sending": "Sending quotation request...",
    "rfq.sendingError": "Error while sending quotation request",
    "rfq.sent": "Quotation request sent!",
    "rfq.sourceOfRequiredParts": "Source of required spare parts",
    "rfq.sourceOfRequiredPartsRequired": "Source of required spare parts is required!",
    "rfq.thirdPartySupplier": "Third party supplier",
    "rfq.timeAndPlace": "Time and place of work",
    "rfq.whenIsWorkCarriedOut": "When should the work be carried out?",
    "rfq.whereIsWorkCarriedOut": "Where will the work be carried out?",
    "routes.SFOCDashboard": "SFOC dashboard",
    "routes.SFOCFleetDashboard": "SFOC Fleet view",
    "routes.SFOCVesselDashboard": "SFOC Vessel view",
    "routes.VATStatements": "VAT statements",
    "routes.contactContractManager": "Contact Contract Manager",
    "routes.contracts": "Agreements",
    "routes.download": "Downloads",
    "routes.equipment": "Product",
    "routes.expertInsight": "Expert Insight",
    "routes.fluidReportDraft": "New fluid report",
    "routes.installation": "Installation",
    "routes.installationInformation": "Information",
    "routes.installationSelection": "Select installation",
    "routes.installationSelection:energy": "Select power plant",
    "routes.installationSelection:marine": "Select vessel",
    "routes.installations": "Installations",
    "routes.invoices": "Invoices",
    "routes.managerDashboard": "Account Overview",
    "routes.newFluidReport": "New fluid report",
    "routes.newRequest": "New request",
    "routes.newSFOCReport": "New SFOC Performance Data report",
    "routes.newVATStatement": "New VAT Statement",
    "routes.news": "News",
    "routes.otherDocuments": "Other Documents",
    "routes.partsCatalogue": "Browse spare parts",
    "routes.performance": "Performance",
    "routes.placeOrder": "Place Order",
    "routes.proceedToOrder": "Proceed to order",
    "routes.releaseNotes": "Wärtsilä Online Versions",
    "routes.reports": "Reports",
    "routes.requestQuotation": "Ask for quotation",
    "routes.requests": "Support",
    "routes.rfq": "Request for Quotation",
    "routes.runningHours": "Running hours",
    "routes.sfocPerformanceDataHistory": "SFOC Performance Data history",
    "routes.shoppingCart": "Shopping Cart",
    "routes.sparePartPackages": "Spare Part Packages",
    "routes.spareParts": "Commerce",
    "routes.technicalDocuments": "Technical documents",
    "routes.trainings": "Trainings",
    "routes.trainings.register": "Register to training",
    "routes.users": "Users",
    "runningHours": "Running hours",
    "runningHours.confirmDialogMessageHigh": "You entered running hours values which are higher than the elapsed time from last update. You can make a request to update the running hours.",
    "runningHours.confirmDialogMessageLow": "You entered running hours values which are lower than the current values. You can make a request to update the running hours.",
    "runningHours.highValue": "You entered a running hours value which is higher than the elapsed time from last update.",
    "runningHours.invalidValue": "Invalid value",
    "runningHours.lottery.dialogText": "Thank you for taking the time to update your engine running hours. In case you want to participate\nin the draw of 5 engine building kits, please click \"participate\" and leave your contact details.",
    "runningHours.lottery.dialogTitle": "Enter to win a Wärtsilä engine building kit",
    "runningHours.lottery.participate": "Participate",
    "runningHours.lowValue": "You entered a running hours value which is lower than the current value.",
    "runningHours.makeRequest": "Make request",
    "runningHours.newRunningHours": "New running hours",
    "runningHours.reasonForHigherValue": "Reason for higher value",
    "runningHours.reasonForHigherLowerValue": "Reason for higher/lower value",
    "runningHours.reasonForLowerValue": "Reason for lower value",
    "runningHours.reasonForRequest": "Reason for request",
    "runningHours.reasonIsRequired": "Reason is required",
    "runningHours.runningHoursDateInPast": "Running hours date cannot be older than last updated date, please choose correct date before continuing.",
    "runningHours.updateHoursMessage": "Some of your engine running hours have not been updated in more than one year.",
    "runningHours.updateRunningHours": "Update running hours",
    "runningHours.youCanMakeRequest": "You can make a request to update the running hours",
    "runningHoursUpdated": "Running hours updated",
    "save": "Save",
    "saveAndClose": "Save and close",
    "saveVisibilitySelection": "Save",
    "searchInsteadFor": "Search instead for",
    "searchPlaceholder": "Search...",
    "select": "Select",
    "select.sortPlaceholder": "Sort by",
    "selectInstallation": "Select installation",
    "selectInstallation:energy": "Select power plant",
    "selectInstallation:marine": "Select vessel",
    "selectInstallations": "Select installations",
    "selectInstallations:marine": "Select vessels",
    "send": "Send",
    "sendingFileFailed": "Failed",
    "services": "Services",
    "sficode": "SFI Code",
    "sficodes": "SFI Codes",
    "showMore": "Show more",
    "showingResultsFor": "Showing results for",
    "sortEquipment": "Sort products",
    "sortInstallations": "Sort installations",
    "sortInstallations:energy": "Sort power plants",
    "sortInstallations:marine": "Sort vessels",
    "sorting.createdByAZ": "Created by (A-Z)",
    "sorting.creationDate": "Creation date",
    "sorting.dueDate": "Due date",
    "sorting.emailShortAZ": "E-mail (A-Z)",
    "sorting.equipmentAZ": "Product (A-Z)",
    "sorting.installationAZ": "Installation (A-Z)",
    "sorting.installationNameAZ": "Installation (A-Z)",
    "sorting.installationNameZA": "Installation (Z-A)",
    "sorting.modified": "Modified",
    "sorting.nameAZ": "Name (A-Z)",
    "sorting.newestFirst": "Newest first",
    "sorting.nickNameAZ": "Nickname (A-Z)",
    "sorting.nickNameZA": "Nickname (Z-A)",
    "sorting.oldestFirst": "Oldest first",
    "sorting.orderNumber": "Order number",
    "sorting.poNumber": "PO number",
    "sorting.relevancy": "Most relevant",
    "sorting.requestTypeAZ": "Request type (A-Z)",
    "sorting.serialNumberAZ": "Product number (A-Z)",
    "sorting.serialNumberZA": "Product number (Z-A)",
    "sorting.statusAZ": "Status (A-Z)",
    "sorting.subjectAZ": "Subject (A-Z)",
    "sorting.titleAZ": "Title (A-Z)",
    "sorting.typeAZ": "Type (A-Z)",
    "sparePartClaim": "Spare Part Claim",
    "status": "Status",
    "switchToQP": "to QuantiParts",
    "switchToWO": "to Wärtsilä Online",
    "techRequest": "Technical Support",
    "technicalDocumentation": "Technical documents",
    "type": "Type",
    "typeToFilter": "Type to filter...",
    "typeToSearch": "Type to search...",
    "unavailable": "Unavailable",
    "unit.cycles": "cycles",
    "unit.hours": "hours",
    "unit.litres": "litres",
    "unknown": "Unknown",
    "update": "Update",
    "updated": "Updated",
    "useOpenSearch": "Use Open Search",
    "users": "Users",
    "validation.characterLimit": "Character limit: {characters}",
    "validation.characters": "{currentCharCount} / {maxCharCount} characters",
    "validation.incorrectPhoneNumber": "Phone number should start with + and contain 9 to 14 digits",
    "viewContracts": "View agreements",
    "visibleInstallations": "Visible installations",
    "visibleInstallations.changeAction": "Make request to add or remove installation",
    "visibleInstallations.changeAction:energy": "Make request to add or remove power plant",
    "visibleInstallations.changeAction:marine": "Make request to add or remove vessel",
    "visibleInstallations.changeLabel": "Are changes required to installation listing?",
    "visibleInstallations.changeLabel:energy": "Are changes required to power plant listing?",
    "visibleInstallations.changeLabel:marine": "Are changes required to vessel listing?",
    "visibleInstallations:energy": "Visible power plants",
    "visibleInstallations:marine": "Visible vessels",
    "warrantyClaim": "Warranty Request",
    "warrantyEndDate": "Warranty end date",
    "wartsilaPhoneNumbers": "Wärtsilä Phone Numbers",
    "yardView.imoNumber": "IMO {imoNumber}",
    "yardView.underConstruction": "Under Construction",
    "year": "year",
    "years": "years",
    "yes": "Yes",
  },
};
